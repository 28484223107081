*{
    padding: 0;
    margin: 0;
}
body{
    max-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
button{
    background-color: rgb(241, 239, 250);
    -webkit-tap-highlight-color: rgba(0, 0, 0,0);
    outline: none;
}

button:active{
    transform: scale(0.9);
}
.Caculator .row:nth-child(2) button{
    background-color: rgb(214, 218, 247);
}
.Caculator .row:nth-child(2)  > button:nth-child(1){
    background-color: rgb(249, 201, 249);
}
.Caculator .row button:nth-last-child(1) {
    background-color: rgb(214, 218, 247);
}
button:disabled{
    background-color: #f1f1f1 !important;
    cursor: no-drop;
    /* color: #f1f1f1; */
}
.Caculator .row:nth-child(1) button{
    width: 4rem;
    height: 4rem;
    background: none;
    font-size: 1.8rem;
    border-radius: 1rem;
}